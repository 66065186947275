













































































































































import { Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import 'vue-good-table/dist/vue-good-table.css'
import { ActionReportService, ActionAnswerService, ActionAnswerNotifyService, ActionSettingsNotifyService, ReactionRequestNotifyService } from '@/services'
import { SortOption, ColumnOption  } from '@/types/good_table_types'
import { ActionReportListData, UserSearchData } from '@/types'
import Filters from '@/utils/filters'

import GoodTableHeader from '@/mixins/good_table_mixin'
import ServiceCallHelper from '@/mixins/service_call_helper'
import UserSearchOptionsHelper from '@/mixins/user_search_options_helper'

import { OverlayLoader, UserSearchModal } from '@/components/parts'

interface MixinInterface extends ServiceCallHelper, GoodTableHeader<ActionReportListData>, UserSearchOptionsHelper {}

@Component({
  components: {
    OverlayLoader,
    UserSearchModal,
    'vue-good-table': require('vue-good-table').VueGoodTable,
  },
  filters: Filters
})
export default class ActionReportTable extends Mixins<MixinInterface>(ServiceCallHelper, GoodTableHeader, UserSearchOptionsHelper) {

  columns = [
    new ColumnOption('user_id', 'userId', {hidden: true}),
    new ColumnOption('名前', 'userName'),
    new ColumnOption('離職予兆', "signAlert", {sortable: false}),
    new ColumnOption('プレイズスコア', "praiseScore"),
    new ColumnOption('回答率', 'checkRate'),
    new ColumnOption('リアクション獲得率', 'reactionRate'),
    new ColumnOption('平均達成率', 'avgAchieveRate'),
    new ColumnOption('アクション設定日', 'approvalDate'),
    new ColumnOption('リスト申請', 'approved', {sortable: false}),
  ]

  pageName = 'userTablePage';

  rows: ActionReportListData[] = [];

  sortOptions = new SortOption(true, []);

  /** 検索条件テキスト */
  filterTexts: string[] = [];

  /**
   * レポート一覧の取得
   */
  loadItems() {
    Vue.$log.debug('loadItems');
    Vue.$log.debug(this.serverParams);
    new ActionReportService().getReportList(this.serverParams).then((response) => {
      Vue.$log.debug(response.data);
      const data = response.data;
      if(data && data.result) {
        this.totalRows = data.result.recordCount;
        this.rows = data.result.report;
        Vue.$log.debug(this.totalRows);
        Vue.$log.debug(this.rows);
      }
    }).finally(()=> {
      this.isLoading = false;
      this.updateFilterOptionsText();
    });
  }

  /** 条件を絞り込み検索ボタン */
  clickFilterButton() {
    this.$modal.show('user-search-modal');
  }

  /** 検索ダイアログmount済み */
  userSearchMounted() {
    const modal = this.$refs.userSearchModal as UserSearchModal
    this.updateFilterText();
  }

  /** 検索ボタンでuserSearchModalをclose */
  userSearch(searchParams: UserSearchData) {
    const actionReportTable = this.$refs.actionReportTable as any;
    Vue.$log.debug('closeUserSearchModal');
    Vue.$log.debug(searchParams);
    this.$modal.hide('user-search-modal');
    this.updateParams({filters: searchParams, page: 1});
    actionReportTable.initializePagination();
    this.updateFilterText();
    this.loadItems();
  }

  /** 検索条件のクリア */
  clearSearchFilter() {
    const userSearchModal = this.$refs.userSearchModal as UserSearchModal;
    const actionReportTable = this.$refs.actionReportTable as any;
    this.clearParams(Object.keys(userSearchModal.fields));
    this.filterTexts = [];
    userSearchModal.clearFields();
    userSearchModal.clearSearchOptionsFilter();
    this.updateParams({ page: 1});
    actionReportTable.initializePagination();
    this.loadItems();
    this.updateFilterText();
  }

  /** 絞り込み検索テキストの更新 */
  updateFilterText() {
    Vue.$log.debug('updateFilterText');
    const userSearchModal = this.$refs.userSearchModal as UserSearchModal;
    this.filterTexts = userSearchModal.searchTexts();
    this.updateFilterOptionsText();
  }

  /** アクションの設定依頼（全メンバー） */
  clickRequestSettingsAll() {
    this.sendMailAll(
      new ActionSettingsNotifyService(),
      this.serverParams,
      '確認画面',
      'アクション未設定の全メンバーへアクションの設定を依頼してよろしいですか？',
    );
  }

  /** アクションの回答依頼（全メンバー） */
  clickRequestAnswerAll() {
    this.sendMailAll(
      new ActionAnswerNotifyService(),
      this.serverParams,
      '確認画面',
      'アクションリスト設定済みの全メンバーへアクションの回答を依頼してよろしいですか？',
    );
  }

  /** アクションの設定依頼（指定メンバー） */
  clickRequestSettings() {
    const rows = this.getSelectedRows(this.$refs.actionReportTable);
    const userIds = rows.map(function(p: ActionReportListData){return p.userId});
    this.sendMailTargetUsers(
      new ActionSettingsNotifyService(),
      userIds,
      '確認画面',
      '選択メンバーへアクションの設定を依頼してよろしいですか？',
    );
  }

  /** アクションの回答依頼（指定メンバー） */
  clickRequestAnswer() {
    const rows = this.getSelectedRows(this.$refs.actionReportTable);
    const userIds = rows.map(function(p: ActionReportListData){return p.userId});
    this.sendMailTargetUsers(
      new ActionAnswerNotifyService(),
      userIds,
      '確認画面',
      '選択メンバーへアクションの回答を依頼してよろしいですか？',
    );
  }

  /** リアクション依頼（指定メンバー） */
  clickRequestReaction() {
    const rows = this.getSelectedRows(this.$refs.actionReportTable);
    const userIds = rows.map(function(p: ActionReportListData){return p.userId});
    this.sendMailTargetUsers(
      new ReactionRequestNotifyService(),
      userIds,
      "確認画面",
      "選択ユーザーへコメントを依頼してよろしいですか？"
    );
  }

  /** アクション回答のダウンロード */
  clickAnswerDownload() {
    const rows = this.getSelectedRows(this.$refs.actionReportTable);
    const userIds = rows.map(function(p: ActionReportListData){return p.userId});
    if(!userIds || userIds.length == 0) {
      this.$swal('ユーザーが選択されていません。', '', 'warning');
    }
    else {
      this.showLoading();
      Vue.$log.debug(this.serverParams)
      new ActionAnswerService().downloadCsv(
        userIds,
        this.serverParams && this.serverParams.filters ? this.serverParams.filters.termFrom : null,
        this.serverParams && this.serverParams.filters ? this.serverParams.filters.termTo : null
      ).then((response) => { 
        this.commonApiErrorHandler(response)
      }).finally(() => {
        this.hideLoading();
      });
    }
  }

}

